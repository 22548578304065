
import { grey } from "@mui/material/colors";
import React from "react"




const FooterB =()=> {
    return (

    <footer class="text-center text-white" >
  
  <div class="text-center p-3" style={{backgroundColor: grey[500]}}>
    © 2022 Copyright: <br/>
    <a class="text-white" href="https://invitatii-web.ro/"> Invitatii-Web.RO & Invitatii-Web.MD</a>
  </div>
    </footer>  

    )
}

export default FooterB;